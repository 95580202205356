import React from 'react';

import com from '../../styles/common.module.scss';

import { AdvantagesCardArray } from './data';
import s from './style/Advantages.module.scss';

export const Advantages: React.FC = () => (
  <section className={com.container}>
    <div className={s.card}>
      <h2 className={com.title__h2_landing}>Преимущества</h2>
      <div className={s.card__items}>
        {AdvantagesCardArray.map(card => (
          <article className={s.item} key={card.text}>
            <div className={s.item__body}>
              <div className={s.item__content}>
                <h3 className={s.item__text}>
                  {card.text} <span className={s.item__text__bold}>{card.boldText}</span>
                </h3>
              </div>
              <div className={s.item__icon}>
                <img src={card.icon} alt="card-icon" />
              </div>
            </div>
          </article>
        ))}
      </div>
    </div>
  </section>
);
