import { useEffect, useState } from 'react';

import { oneSecond, TIME } from '../../../constants';

const INITIAL_COUNT = 0;
export const useCounter = () => {
  const [count, setCount] = useState(INITIAL_COUNT);
  const { sec, min, hours } = TIME;
  useEffect(() => {
    const date = new Date();
    const getMinutes = () =>
      (date.getHours() * hours + date.getMinutes() * min + date.getSeconds()) / sec;
    const interval: ReturnType<typeof setInterval> = setInterval(() => {
      setCount(getMinutes);
    }, oneSecond);
    return () => clearInterval(interval);
  }, [count]);

  return Math.floor(count);
};
