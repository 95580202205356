import clock from '../../../assets/icons/how-it-work/clock.svg';
import document from '../../../assets/icons/how-it-work/document.svg';
import money from '../../../assets/icons/how-it-work/money.svg';

type HowItWorksDataType = {
  icon: string;
  title: string;
  subtitle: string;
};

export const howItWorksData: HowItWorksDataType[] = [
  {
    icon: document,
    title: 'Оформите заявку',
    subtitle: 'Заполните онлайн-анкету это займет 5 минут',
  },
  {
    icon: clock,
    title: 'Дождитесь ответа',
    subtitle: 'Ответ придет в течение\n 2 минут',
  },
  {
    icon: money,
    title: 'Получите деньги',
    subtitle: 'Наличными или на карту\n вашего банка',
  },
];
