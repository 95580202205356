import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AdditionalInfoType,
  CreditFormType,
  CreditParameterInfo,
  PassportInfo,
  WorkInfo,
} from 'store/creditFormSlice/types';
import {
  CreditProduct,
  EmploymentTypeValue,
  OptionsItemEmployment,
  PathEmploymentType,
} from 'types';

const creditFormInitialState: CreditFormType = {
  product_type: 'credit_card',
  credit_parameter_info: {
    credit_sum: '',
    name: '',
    surname: '',
    patronymic: '',
    phone_number: '',
    gender: null,
    credit_target: { value: 'credit_card', title: 'Кредитная карта' },
    checked: true,
    email: '',
    email_generated: true,
  },
  work_info: {
    activity: null,
    employment_type: { value: 'WORKACTIVITY.7', title: 'Не работаю', path: 'standing' },
    organization_info: null,
    work_address: null,
    start_work: null,
    expirience: null,
    job_title: '',
    phone_work: '',
    monthly_income: '',
    work_activity_type: {
      value: 'CLIENT.ACTIVITY.SCOPE.7',
      title: 'Реклама, маркетинг, связи с общественностью',
    },
  },
  additional_info: {
    education: null,
    family_status: null,
    children: null,
    having_car: null,
    having_real_estate: null,
  },
  passport_info: {
    series_and_number: null,
    registration_date: null,
    registration_address: null,
    issued_date: null,
    issued_by: null,
    fact_address: null,
    department_code: null,
    date_birthday: null,
    secret_key: null,
    second_contact_phone: null,
    second_contact_name: null,
    contact_phone: null,
    contact_name: null,
    checked: true,
    born_city: null,
  },
};

const creditFormSlice = createSlice({
  name: 'credit-form',
  initialState: creditFormInitialState,
  reducers: {
    setProductType: (state, action: PayloadAction<CreditProduct>) => {
      state.product_type = action.payload;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.credit_parameter_info.phone_number = action.payload;
    },
    setCreditInfo: (state, action: PayloadAction<CreditParameterInfo>) => {
      state.credit_parameter_info = action.payload;
    },
    setWorkInfo: (state, action: PayloadAction<WorkInfo>) => {
      state.work_info = action.payload;
    },
    setEmploymentType: (
      state,
      action: PayloadAction<
        OptionsItemEmployment<EmploymentTypeValue, PathEmploymentType>
      >,
    ) => {
      state.work_info.employment_type = action.payload;
    },
    setAdditionalInfo: (state, action: PayloadAction<AdditionalInfoType>) => {
      state.additional_info = action.payload;
    },
    setPassportInfo: (state, action: PayloadAction<PassportInfo>) => {
      state.passport_info = action.payload;
    },
  },
});

export const creditReducer = creditFormSlice.reducer;
export const {
  setCreditInfo,
  setEmploymentType,
  setAdditionalInfo,
  setPhoneNumber,
  setProductType,
  setWorkInfo,
  setPassportInfo,
} = creditFormSlice.actions;
