import React from 'react';

import ReactDOM from 'react-dom';
import 'styles/reset.scss';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { MediaProvider } from './context/MediaProvider';

import { App } from 'pages/App';
import { store } from 'store';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <MediaProvider>
        <App />
      </MediaProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);
