import React, { Fragment, useContext, useState } from 'react';

import { MediaContext } from '../../context/MediaProvider';
import com from '../../styles/common.module.scss';

import { atUsDataTab } from './data/data';
import s from './style/AtUs.module.scss';

const ACTIVE_INITIAL_STATE = 1;
export const AtUs: React.FC = () => {
  const [active, setActive] = useState(ACTIVE_INITIAL_STATE);
  const tablet = useContext(MediaContext);

  const cardClickHandler = (id: number) => {
    setActive(id);
  };

  return (
    <section className={`${tablet ? com.container : ''}`}>
      <div className={s.atUs}>
        <h2 className={com.title__h2_landing}>У нас</h2>
        <div className={s.atUs__items}>
          <div className={`${s.atUs__grid} ${!tablet && com.no_scroll_block}`}>
            {atUsDataTab.map(card => (
              <Fragment key={card.id}>
                <div className={s.item__0}>
                  <div
                    className={`${s.description} ${
                      active !== card.id ? s.notActive : s.active
                    }`}
                  >
                    <h2 className={s.description__title}>{card.title}</h2>
                    <div
                      className={`${s.description__line} ${s[`description__${card.id}`]}`}
                    />
                    <p className={s.description__text}>{card.description}</p>
                  </div>
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  className={`${s.item} ${s[`item__${card.id}`]} ${
                    active === card.id ? s[`active__${card.id}`] : ''
                  }`}
                  onClick={() => cardClickHandler(card.id)}
                  onKeyDown={() => cardClickHandler(card.id)}
                >
                  <div className={s.atUs__image}>
                    <img src={card.icon} alt="At us icon" />
                  </div>
                  <h4 className={s.atUs__title}>{card.title}</h4>
                  <p className={s.atUs__subtitle}>{card.subtitle}</p>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
