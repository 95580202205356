import React, { FC, useEffect } from 'react';

import { Advantages } from '../components/Advantages';
import { AtUs } from '../components/AtUs';
import { Banner } from '../components/Banner';
import { Footer } from '../components/Footer';
import { HowItWorks } from '../components/HowItWorks';
import { IssueMfo } from '../components/IssueMFO';
import { OurPartners } from '../components/OurPartners';
import { Question } from '../components/Question';
import { useHistoryWithUTM } from '../hook/useHistoryWithUTM';

import s from './App.module.scss';

import { MFO } from 'components/MFO';

export const App: FC = () => {
  const history = useHistoryWithUTM();
  useEffect(() => {
    history.toHttps();
  }, []);

  return (
    <div>
      <div className={s.wrapper}>
        <main className={s.page}>
          <div className={s.layout}>
            <Banner />
            <Advantages />
            <MFO />
            <HowItWorks />
            <OurPartners />
            <AtUs />
            <IssueMfo />
            <Question />
            <Footer />
            {/* <FooterOld /> */}
          </div>
        </main>
      </div>
    </div>
  );
};
