import React, { useContext } from 'react';

import backgroundImage from '../../assets/background/background-desktop.webp';
import imageDesktop from '../../assets/banner/home/woman-desktop.webp';
import imageMobile from '../../assets/banner/home/woman-phone.webp';
import { LOGO } from '../../constants';
import { MediaContext } from '../../context/MediaProvider';
import com from '../../styles/common.module.scss';
import { Logo } from '../Icons/Logo';

import { BannerTextBlock } from './components/BannerTextBlock';
import s from './style/Banner.module.scss';

import { ReactComponent as User } from 'assets/icons/user.svg';

const background = {
  home: 'linear-gradient(180deg, #FFAF03 0%, #FF7C03 75.52%, #FFFFFF 100%)',
  polygon:
    'polygon(100% 0px, 100% 91.82%, 96.08% 92.92%, 92.63% 93.92%, 87.34% 95.38%, 79.2% 97.13%, 74.61% 97.91%, 69.1% 98.78%, 54.57% 99.72%, 0px 100%, 0px 0px)',
};

export const Banner: React.FC = () => {
  const tablet = useContext(MediaContext);

  return (
    <>
      <section
        className={s.banner}
        style={{
          background: tablet
            ? `url(${backgroundImage}) center / cover no-repeat`
            : background.home,
          clipPath: tablet ? background.polygon : '',
        }}
      >
        <div className={com.container}>
          <div className={s.banner__logo}>
            <Logo
              size={tablet ? LOGO.desktop : LOGO.tablet}
              containerStyle={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
              color="whiteColor"
            />

            <button className={s.banner__personal_btn} type="button">
              <User />
              Личный кабинет
            </button>
          </div>
          <div className={s.banner__body}>
            <div className={`${s.banner__column} ${s.banner__image}`}>
              <picture>
                <source
                  srcSet={imageDesktop}
                  type="image/webp"
                  media="(min-width: 767.98px)"
                />
                <source
                  srcSet={imageMobile}
                  type="image/webp"
                  media="(max-width: 767.98px)"
                />
                <img src={imageDesktop} alt="card" />
              </picture>
            </div>
            <div className={`${s.banner__column} ${s.banner__text_block}`}>
              <BannerTextBlock subtitle button viewport={!tablet} />
            </div>
          </div>
        </div>
      </section>
      {!tablet && (
        <div className={com.container}>
          <BannerTextBlock className={s.banner__mobile} subtitle button />
        </div>
      )}
    </>
  );
};
