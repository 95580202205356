import React from 'react';

import com from '../../styles/common.module.scss';

import s from './style/Footer.module.scss';

import { Accordion } from 'shared/Accordion';

export const Footer: React.FC = () => (
  <div className={s.footer}>
    <div className={`${com.container} ${com.container__footer}`}>
      <div className={s.info}>
        <Accordion title="Информация для клиентов">
          <>
            <span className={s.info__text}>
              Бесплатный сервис (домен) позволяет подать заявку и узнать окончательное
              решение сразу от нескольких МФО, после чего останется выбрать подходящее
              предложение и получить его удобным способом: на карту или наличными.
              <br />
              Информацию о процентных ставках по договорам кредитования с физическими
              лицами можно узнать у каждого Партнера индивидуально на официальном сайте
              Партнера или в офисах Партнера.
            </span>
            <span className={s.info__text}>
              Заполняя заявку на (домен) вы даете свое согласие на обработку персональных
              данных, Политику относительно обработки персональных данных и принимаете
              оферту.
            </span>
            <span className={s.info__text}>
              Оставаясь на сайте (домен) и продолжая работу с ним Вы даете согласие на
              использование файлов cookie.
            </span>
            <span className={s.info__text}>
              Сайт (домен) оказывает исключительно информационные услуги подбора кредитных
              предложений и не несет ответственности за правовые отношения между МФО
              (Партнером) и Пользователем сайта. Услуги на сайте оказывает ООО Фин Промоут
              Системс ОГРН{' '}
            </span>
            <span className={s.info__text}>
              Copyright ©2024 “Онлайн-банк”. Все права защищены. Возрастное ограничение
              18+
            </span>
          </>
        </Accordion>
      </div>
    </div>
  </div>
);
