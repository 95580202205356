import React from 'react';

import { Link } from 'react-scroll';

import { Button } from '../../../shared/Button';
import s from '../style/Banner.module.scss';

type BannerTextBlockPropsType = {
  className?: string;
  subtitle: boolean;
  button: boolean;
  viewport?: boolean;
};

export const BannerTextBlock: React.FC<BannerTextBlockPropsType> = ({
  className,
  subtitle,
  button,
  viewport,
}) => (
  <div className={className ?? className}>
    <div className={s.banner__title}>
      Автоодобрение по номеру телефона до 30 000 рублей
    </div>
    {subtitle && <div className={s.banner__subtitle}>Первый заём 0%</div>}
    {button && (
      <Link to="form-title" spy smooth>
        <Button className={s.banner__button}>Оформить заявку</Button>
      </Link>
    )}
  </div>
);
