import React, { useContext } from 'react';

import { Link } from 'react-scroll';

import { MediaContext } from '../../context/MediaProvider';
import { Button } from '../../shared/Button';
import com from '../../styles/common.module.scss';

import { useCounter } from './hook/useCounter';
import s from './style/IssueMFO.module.scss';

export const IssueMfo: React.FC = () => {
  const tablet = useContext(MediaContext);
  const count = useCounter();

  return (
    <div className={`${tablet ? com.container : ''}`}>
      <div className={s.issue}>
        <div className={s.issue__title}>
          <span>
            Сегодня выдано займов: <b>{count}</b>
          </span>
          <span>В течение 10 минут одобрим и Вам</span>
        </div>
        <Link to="form-title" spy smooth>
          <Button className={s.issue__button}>Получить деньги</Button>
        </Link>
      </div>
    </div>
  );
};
