import React, { useContext, useState } from 'react';

import arrow from '../../assets/other/arrow.svg';
import bank from '../../assets/other/bank.webp';
import { MediaContext } from '../../context/MediaProvider';
import com from '../../styles/common.module.scss';

import { questionData } from './data';
import s from './style/Question.module.scss';

const ACTIVE_INITIAL_STATE = 0;
export const Question: React.FC = () => {
  const [active, setActive] = useState(ACTIVE_INITIAL_STATE);
  const tablet = useContext(MediaContext);
  const zero = 0;
  const one = 1;

  const onClickHandler = async (id: number) => {
    setActive(zero);
    if (id !== active) {
      setActive(id);
    } else {
      setActive(zero);
    }
  };

  return (
    <div className={com.container}>
      <div className={s.question}>
        <h2 className={com.title__h2_landing}>Частые вопросы</h2>
        <div className={s.question__body}>
          <div className={`${s.question__column} ${s.question__left}`}>
            {questionData(tablet).map((item, i) => (
              <div className={s.question__content} key={item.title}>
                <span
                  role="button"
                  tabIndex={0}
                  className={s.question__item}
                  onClick={() => onClickHandler(i + one)}
                  key={item.title}
                  onKeyDown={() => onClickHandler(i + one)}
                >
                  <span className={s.question__row}>
                    <p className={s.question__title}>{item.title}</p>
                    <div className={s.question__icon}>
                      <img
                        className={active === i + one ? s.icon__active : ''}
                        src={arrow}
                        alt="arrow icon"
                      />
                    </div>
                  </span>
                  <p
                    className={`${s.question__description} ${
                      active === i + one ? s.active : ''
                    }`}
                  >
                    {item.description}
                  </p>
                </span>
              </div>
            ))}
          </div>
          <div className={`${s.question__column} ${s.question__right}`}>
            <img src={bank} alt="bank" />
          </div>
        </div>
      </div>
    </div>
  );
};
