import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';

import { appReducer } from 'store/appSlice/appSlice';
import { creditReducer } from 'store/creditFormSlice/creditFormSlice';
import { dadataReducer } from 'store/dadataSlice/dadataSlice';

const rootReducer = combineReducers({
  app: appReducer,
  credit: creditReducer,
  dadata: dadataReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
});

export type AppRootStateType = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunkType = ThunkAction<void, AppRootStateType, unknown, Action<string>>;
